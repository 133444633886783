.table-responsive {
    width: 100vw;
    margin: 0 auto 100px;

    &+button.btn.for-form {
        display: none;
    }

    table {

        thead {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            background-color: var(--primary-color);
            z-index: 110;

            .isOpenMenu & {
                z-index: 0;

            }

            tr {
                th {
                    font-family: var(--font-s-b);
                    padding: 10px 12px;
                    font-size: 0.875rem;
                    border-bottom: 1px solid;
                    color: var(--secondary-color);
                    vertical-align: middle;
                }
            }
        }

        tbody {
            tr.parent.even {
                background-color: rgb(225, 255, 213);
            }

            tr.parent.odd {
                background-color: rgb(237, 236, 255);
            }

            tr {
                &.parent {
                    border-bottom: 1px solid;
                    border-top: 1px solid;
                }

                &.child {
                    &.odd {
                        background-color: rgb(248, 255, 227);
                    }

                    &.even {
                        background-color: rgb(255, 234, 247);
                    }
                }

                td {
                    width: auto;
                    padding: 10px 12px;
                    text-align: center;
                    font-size: 0.875rem;
                }

                &.thead-child {

                    td {
                        font-family: var(--font-s-b);
                        padding: 10px 12px;
                        font-size: 0.875rem;
                    }

                    &.border-bottom {
                        td {
                            border-bottom: 1px solid var(--primary-color);
                        }
                    }
                }
            }
        }
    }
}

.risultati {
    display: inline-block;
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px 8px 0 0 ;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    top: 0;
    transition: 0.3s all;

    @media (max-width: 768px) {
        left: 40px;
        transform: unset;
    }

    &.show {

    }

    p {
        margin-bottom: 10px;
    }

    .info-box {
        position: relative;
        z-index: 1;
    }

    button {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        border-radius: 100% 100% 100% 100%;
        display: flex;
        justify-content: center;
        background-color: var(--primary-color);
        border: none;
        height: 100px;
        z-index: -1;
        font-family: var(--font-d);
        font-size: 1rem;
        box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.10);

        span {
            display: block;
            border-radius: 100%;
            border: 2px solid var(--primary-color);
            width: 30px;
            height: 30px;
            transform: translateY(6px);
            background-color: #ffd397;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 0;
            transition: all 0.3s;
        }

        // &::after {
        //     content: '';
        //     position: absolute;
        //     left: 50%;
        //     top: -12px;
        //     width: 114px;
        //     height: 149px;
        //     transform: translateX(-50%);
        //     background-color: #ffd397;
        //     border-radius: 100% 100% 0 0;
        //     z-index: -2;
        // }

        &:hover {
            span {
                background-color: var(--primary-color);
                color: var(--secondary-color);
            }
        }
    }
}