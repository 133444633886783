#headerInvitation {
    border-radius: 4px;
    overflow: hidden;
    font-family: var(--font-d);
    
    .hero-image-box {
        height: 24vw;
        background-image: url("../../assets/images/jpg/riga.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center -400px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media not all and (min-resolution: 0.001dpcm) {
            background-attachment: unset;
        }

        @media (max-width: 900px) {
            background-attachment: unset;
            background-position: center center;
            height: 40vw;
        }

        // &::after {
        //     content: "";
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     width: 100%;
        //     height: 100%;
        // }

        span {
            background-color: var(--secondary-color);
            color: var(--primary-color);
            padding: 2px 10px;
            position: absolute;
            left: 10px;
            bottom: 10px;
            font-size: 1.2rem;
            border-radius: 3px;
            font-weight: bold;

            @media (max-width: 768px) {
                bottom: 30px;
            }
        }

        p {
            margin: 0;
            z-index: 1;
            font-size: 3rem;
            color: var(--secondary-color);
            border-radius: 8px;
            padding: 10px 30px;

            @media (max-width: 767.98px) {
                font-size: 1.5rem;
            }
        }
    }
}
