footer {
    background: linear-gradient(0deg, rgba(106, 66, 77, 1) 0%, rgba(106, 66, 77, 0.6) 100%);
    min-height: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 768px) {
        padding-bottom: 80px;
    }

    color: var(--secondary-color);
    text-align: center;

    h3 {
        margin-bottom: 20px;
        font-size: 1.6rem;
    }

    a {
        color: var(--secondary-color);
    }

    ul {
        font-size: 0.875rem;
        display: flex;
        justify-content: center;

        li:first-child {
            padding-right: 20px;
            border-right: 1px solid;
        }

        li:last-child {
            padding-left: 20px;
        }

        ul {
            flex-direction: column;
            gap: 0;

            li:first-child {
                border: none;
                padding: 0;
            }
            li:last-child {
                border: none;
                padding: 0;
            }

        }

        li {
            span {
                display: block;
                margin-bottom: 10px;
                font-family: var(--font-s-b);
            }

            margin-bottom: 4px;
        }
    }
}