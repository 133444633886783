/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  line-height: normal;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Dancing Script";
  src: url("./assets/fonts/dancingScript/DancingScript-Bold.eot");
  src: url("./assets/fonts/dancingScript/DancingScript-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/dancingScript/DancingScript-Bold.woff2") format("woff2"),
    url("./assets/fonts/dancingScript/DancingScript-Bold.woff") format("woff"),
    url("./assets/fonts/dancingScript/DancingScript-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "source_san_pro_regular";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "source_san_pro_regular_italic";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "source_san_pro_semibold";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "source_san_pro_bold";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "source_san_pro_bold_italic";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "playfair_bold";
  src: url("./assets/fonts/Playfair_Display_SC/PlayfairDisplaySC-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "playfair_black_italic";
  src: url("./assets/fonts/Playfair_Display_SC/PlayfairDisplaySC-BlackItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


:root {
  --primary-color: #6A424D;
  --secondary-color: #f6f5fa;
  --font-s-r: 'source_san_pro_regular';
  --font-s-r-i: 'source_san_pro_regular_italic';
  --font-s-sb: 'source_san_pro_semibold';
  --font-s-b: 'source_san_pro_bold';
  --font-s-b-i: 'source_san_pro_bold_italic';
  --font-d: 'Dancing Script';
  --font-p-b: 'playfair_bold';
  --font-p-bl-i: 'playfair_black_italic';
}

#root {
  position: relative;
}

i {
  font-family: var(--font-s-r-i);
}

html {
  // scroll-behavior: smooth;
}

body,
button {
  color: var(--primary-color);
  font-family: var(--font-s-r);
  background-color: var(--secondary-color);
}

.it_it {
  *.lv_lv {
    display: none;
  }
}

.lv_lv {
  *.it_it {
    display: none;
  }
}

.d-none {
  display: none;
}

button,
a {
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    transition: background-color 0.2s ease-in-out;
  }

  &.link {
    border: none;
  }

  &:disabled {
    cursor: auto;
  }
}

button.btn {
  border-radius: 0.25rem;

  &.only-icon {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-d);
    margin: 0 10px;
    font-size: 1rem;
    line-height: 0;
  }
}

.btn-primary {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: var(--secondary-color);
  }
}

.btn.btn-outline-primary {
  border: 2px solid var(--primary-color);
  background-color: var(--secondary-color);
  color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
}

.overflow-hidden {
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-d);
}

h1 {
  font-size: 3rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 2rem;

  @media (max-width: 768px) {
    font-size: 1.75rem
  }
}

h3 {
  font-size: 1.8rem;

  @media (max-width: 768px) {
    font-size: 1.5rem
  }
}

h4 {
  font-size: 1.6rem;
}

p {
  @media (max-width: 768px) {
    font-size: 0.875rem
  }
}

.close-button {
  width: 40px;
  height: 40px;
  border: none;
  padding: 10px;
  z-index: 100;
  border-radius: 100%;
  background-color: var(--primary-color);
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;

  div {
    position: relative;
    height: 16px;
    width: 20px;
    margin: 0 auto;

    span {
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: var(--secondary-color);
      border-radius: 30px;

      &:nth-child(1) {
        transition: all 0.3s;
        transform: rotate(45deg);
        top: 7px;

        &::after {
          right: calc(50% - 1px);
        }
      }

      &:nth-child(2) {
        width: 0;

        &::after {
          content: none;
        }
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        bottom: 7px;

        &::after {
          right: calc(50% - 1px);
        }
      }

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: var(--primary-color);
        right: 4px;
        transition: right 0.3s;
      }
    }
  }
}

.text-center {
  text-align: center;
}

.d-block {
  display: block;
}

.w-100 {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.d-none {
  display: none !important;
}

.alert {
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  font-family: var(--font-s-sb);
  text-align: left;
  border-radius: 4px;

  &.alert-warning {
    background-color: #ffd397;
  }

  &.alert-success {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }
}

input:focus,
input:focus-visible,
textarea:focus,
textarea:focus-visible {
  outline: var(--primary-color) auto 1px;
}

.input-radio-box {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  width: 18px;
  height: 18px;
  bottom: -3px;

  input[type=radio] {
    opacity: 0;
    width: 18px;
    height: 18px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &~span {
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 2px solid var(--primary-color);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      border-radius: 100%;
    }

    &:checked~span::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--primary-color);
      border-radius: 100%;
    }

    &:focus-visible {
      &~span {
        outline: var(--primary-color) auto 2px;
        outline-offset: 4px;
      }
    }
  }
}

b {
  font-family: var(--font-s-b);
}

.border-radius-circle {
  border-radius: 100% !important;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary-color);
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #81696f;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}