#propose {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 40px);

  .title-seciont-box {

    h2 {
      text-align: center;
      position: relative;
      display: inline-block;
      text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);

      span {
        letter-spacing: 0.8rem;

        @media (max-width: 1080px) {
          letter-spacing: 0.3rem;
        }

        @media (max-width: 768px) {
          max-width: 50%;
        }
      }


      #heroImage & {
        margin-bottom: 0px;

        &::before,
        &::after {
          content: none;
        }
      }

      // &::before,
      // &::after {
      //     content: '';
      //     position: absolute;
      //     height: 40px;
      //     width: 40px;
      // }

      // &::before {
      //     border-radius: 100% 0 100% 0;
      //     border-top: 2px solid #eeb74d;
      //     left: -40px;
      //     top: -20px;

      // }

      // &::after {
      //     border-radius: 100% 0 100% 0;
      //     border-bottom: 2px solid #eeb74d;
      //     right: -40px;
      //     bottom: -20px;
      // }
    }
  }

  .video-box {
    position: relative;
    border-radius: 8px;
    padding: 0;
    overflow: hidden;
    height: auto;
    margin-bottom: 10px;

    video {
      margin: 0 auto;
      transition: all 0.3s;
      height: 100%;
      width: 650px;
      max-width: 100%;


      // &::-webkit-media-controls-play-button {
      //   display: none;
      // }
    }

    button {
      position: absolute;
      width: 100px;
      height: 100px;
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      border-radius: 100%;
      z-index: 10;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .pause {
        display: none;
        transform: translateX(0px);
      }

      svg {
        transform: translateX(5px);

        path,
        rect {
          fill: rgba(246, 245, 250, 0.3);
          transition: all 0.3s;
        }
      }

      &:hover {
        svg {

          path,
          rect {
            fill: rgba(246, 245, 250, 1);
          }
        }
      }
    }
  }

  &.play {
    .video-box {
      button {
        background-color: rgba(0, 0, 0, 0.1);

        .play {
          display: none;
        }

        .pause {
          display: block;
        }
      }
    }
  }

  .description-box {
    width: 100%;
    max-width: 650px;

    p {
      font-family: var(--font-s-r-i);
      line-height: 1.5rem;

      b {
        font-family: var(--font-s-b-i);
      }
    }

    margin-bottom: 20px;

    @media (max-width: 768px) {
      p {
      }
    }
  }

  .photo-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 650px;
    align-items: flex-start;
    gap: 10px;

    &-child {
      width: calc(100% / 3 - 7px);
      border-radius: 4px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:nth-child(3n + 1) {
        height: 170px;

        &:not(.first) {
          margin-top: 0px;
          height: 180px;
        }
      }

      &:nth-child(3n + 2) {
        height: 140px;

        &:not(.second) {
          height: 180px;
          margin-top: -30px;
        }

        &.penultimate {
          height: 210px;
        }
      }

      &:nth-child(3n + 3) {
        height: 170px;

        &:not(.third) {
          height: 180px;
          margin-top: 0px;
        }
      }

      img {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        object-fit: cover;
      }

      &:hover {
        transition: all 0.2s ease-in-out;
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5);
      }
    }
  }
}