.line-date {
  position: fixed;
  height: calc(100vh - 50px);
  top: 50px;
  width: 2px;
  background-color: var(--primary-color);
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.4);
  z-index: 2;
  left: 49px;

  span {
    display: block;
    position: absolute;
    background-color: var(--primary-color);
    width: 50px;
    height: 50px;
    color:  var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 1000;
    letter-spacing: 0.2rem;
    font-size: 1.0rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    &.day {
      bottom: 200px;
      left: -5px;
    }
    &.month {
      bottom: 120px;
      left: 10px;
    }
    &.year {
      bottom: 40px;
      left: -10px;
    }
  }
}
