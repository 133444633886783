.menu-hamburger-button {
  width: 40px;
  height: 40px;
  border: none;
  padding: 10px;
  position: fixed;
  z-index: 100;
  top: 30px;
  left: 30px;
  border-radius: 100%;
  background-color: var(--primary-color);
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border: 2px solid var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  font-family: var(--font-d);

  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
  }

  &:hover {
    transition: transform 0.3s;
    transform: scale(1.2);

    div span:nth-child(1) {
      top: 90%;
    }

    div span:nth-child(3) {
      bottom: 90%;
    }
  }

  div {
    position: relative;
    height: 16px;
    width: 20px;
    margin: 0 auto;

    span {
      display: block;
      width: 100%;
      height: 2px;
      position: absolute;
      background-color: var(--secondary-color);
      border-radius: 30px;

      &:nth-child(1) {
        top: 0;
        transition: all 0.3s;

        .show & {
          transform: rotate(45deg);
          top: 7px;

          &::after {
            right: calc(50% - 1px);
          }
        }
      }

      &:nth-child(2) {
        top: 50%;
        transform: translateY(-50%);

        &::after {
          right: 12px;
        }

        transition: width 0.3s;

        .show & {
          width: 0;

          &::after {
            right: 0;
          }
        }
      }

      &:nth-child(3) {
        bottom: 0;
        transition: all 0.3s;

        .show & {
          transform: rotate(-45deg);
          bottom: 7px;

          &::after {
            right: calc(50% - 1px);
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 2px;
        background-color: var(--primary-color);
        right: 4px;
        transition: right 0.3s;
      }
    }
  }
}

.menu-hamburger {
  display: flex;
  position: fixed;
  background: rgba(105, 68, 78, 0.4);
  width: 0%;
  height: 0%;
  color: var(--secondary-color);
  z-index: 99;
  top: 0%;
  border-radius: 100%;
  left: 0%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  visibility: hidden;
  transition: all 1s;
  font-family: var(--font-d);

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  ul {
    // border: 1px solid var(--secondary-color);
    visibility: hidden;
    opacity: 0;
    padding: 10%;
    border-radius: 5px;
    filter: blur(1.5rem);
    transition: all 0.3s;

    li {
      text-align: center;
      margin-bottom: 20px;

      a {
        color: var(--secondary-color);
        text-decoration: none;
        letter-spacing: 0.4em;
        transition: font-size 0.3s, text-shadow 0.3s;
        backface-visibility: hidden;
        position: relative;
        z-index: 2;
        padding: 0 10px;

        &:hover {
          text-shadow: 2px 2px 10px rgb(0, 0, 0);

          &::before,
          &::after {
            opacity: 1;
            width: 100%;
          }

          &::before {
            left: -100%;
          }

          &::after {
            left: 100%;
          }
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: 0%;
          top: 50%;
          left: 50%;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s ease-out, left 1s, width 1s;
        }

        &::before {
          background: rgb(105, 68, 78);
          background: linear-gradient(90deg, rgba(105, 68, 78, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
        }

        &::after {
          background: rgb(255, 255, 255);
          background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(105, 68, 78, 1) 100%);
        }

        span {

          letter-spacing: 0.4rem;
        }
      }
    }
  }

  .show & {
    visibility: visible;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    top: 5px;
    transform: unset;
    left: 5px;
    border-radius: 8px;
    background: var(--primary-color);

    ul {
      opacity: 1;
      visibility: visible;
      transition: all 2s;
      filter: blur(0rem);
    }
  }
}

header {

  &::after {
    transition: all 1s -1s;
    content: '';
    background-color: var(--secondary-color);
    width: 0;
    height: 0;
    position: fixed;
    z-index: 98;
    left: 0;
    top: 0;
    opacity: 0;
  }

  &.show {

    &::after {
      width: 100%;
      height: 100%;
      transition: all 1s 0.5s;
      opacity: 1;
    }
  }
}