#tips {

    .link-box {
        position: fixed;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;

        @media (max-width: 768px) {
            position: -webkit-sticky;
            position: sticky;
            width: fit-content;
            top: 4px;
            margin: auto;
            transform: unset;
        }

        ul {
            gap: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: var(--secondary-color);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);
            padding: 10px;
            border-radius: 100px;

            @media (max-width: 768px) {
                flex-direction: row;
            }

            li {
                button {
                    border-radius: 100%;
                    border: 1px solid var(--primary-color);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px;

                    img {
                        height: 20px;
                        width: 20px;
                        object-fit: contain;
                    }

                    &:hover {
                        background-color: var(--primary-color);
                    }

                    &.active {
                        background-color: var(--primary-color);
                    }
                }
            }
        }
    }

    .tips-box {
        >div {
            padding: 100px 20vw;
            min-height: 100vh;
            letter-spacing: 0.05rem;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            z-index: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;


            .description {
                -webkit-backdrop-filter: blur(6px);
                backdrop-filter: blur(6px);
                padding: 30px 20px;
                border-radius: 8px;
                background-color: rgba(246, 245, 250, 0.6);
                max-height: calc(100vh - 200px);
                overflow-y: auto;
            }

            &#thermometer {
                background-image: url(../../assets/images/jpg/riga_snow.jpg);
            }

            &#suitcase {
                background-image: url(../../assets/images/jpg/suitecase.jpg);
            }

            &#car {
                background-image: url(../../assets/images/jpg/taxi.jpg);
            }
            &#food {
                background-image: url(../../assets/images/jpg/food.jpg);
            }

            &::before {
                content: '';
                position: absolute;
                left: 0%;
                width: 100%;
                height: 100%;
                top: 0;
                z-index: -1;
                background: rgb(246, 245, 250);
                background: linear-gradient(0deg, rgba(246, 245, 250, 1) 0%, rgba(246, 245, 250, 0) 50%, rgba(246, 245, 250, 1) 100%);
            }

            // &::after {
            //     content: '';
            //     position: absolute;
            //     right: 0;
            //     height: 50%;
            //     width: 2px;
            //     background: rgb(105, 68, 78);
            //     background: linear-gradient(360deg, rgba(105, 68, 78, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
            //     top: 50%;
            //     transform: translateY(-50%);
            //     border-radius: 100px;

            //     @media (max-width: 768px) {
            //         top: unset;
            //         bottom: 0;
            //         right: unset;
            //         transform: unset;
            //         height: 2px;
            //         width: 100%;
            //         background: linear-gradient(-90deg, rgba(105, 68, 78, 1) 0%, rgba(255, 255, 255, 0.8) 100%);
            //     }
            // }

            @media (max-width: 768px) {
                padding: 0;
                padding: 100px 20px;
            }

            // &:first-child {
            //     min-height: calc(100vh - 228px);
            // }

            h3 {
                margin-bottom: 40px;
                position: relative;
                padding-left: 50px;

                @media (max-width: 768px) {
                    font-size: 1.25rem;
                }

                &::before {
                    content: '';
                    background: url(../../assets/images/png/logo.png) no-repeat center;
                    background-size: contain;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 20px;
                    width: 20px;
                    z-index: 0;
                    border-radius: 100%;
                    border: 2px solid var(--secondary-color);
                    box-shadow: 6px 0px 10px rgba(105, 68, 78, 0.4);
                    padding: 2px;
                    background-color: var(--secondary-color);

                }

            }

            p {
                margin-bottom: 10px;
            }



            ul {
                margin-block-start: 1em;
                margin-block-end: 1em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;
                list-style-type: disc;

                li {
                    margin-bottom: 4px;
                }
            }
        }
    }
}