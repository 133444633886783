#WeddingList {
    background: rgb(106, 66, 77);
    background: linear-gradient(0deg, rgba(106, 66, 77, 0.6) 0%, rgba(246, 245, 250, 1) 80%);
    margin-top: 200px;

    .description {
        color: var(--primary-color);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        padding: 10px;
        border-radius: 8px;
        max-width: 100%;
        width: 600px;
        margin: 0 auto 20px;
        text-align: center;
        font-family: var(--font-s-b-i);

        p {
            line-height: 2rem;
            margin-bottom: 20px;
        }
    }

    .card {
        // height: 300px;
        border-radius: 3px;
        -webkit-perspective: 150rem;
        perspective: 150rem;
        -moz-perspective: 150rem;
        border-radius: 30px;
        margin: 0 auto 100px;
        display: flex;
        height: 0;
        padding-bottom: 334px;
        max-width: 528px;
        // animation: 5s ease 0s infinite beat;

        &:hover {
            animation: unset;

            .card__side {
                animation: unset;
            }
        }

        @keyframes beat {

            0%,
            50%,
            100% {
                transform: scale(1, 1);
            }

            30%,
            80% {
                transform: scale(0.92, 0.95);
            }
        }

        @media (max-width: 758px) {
            padding-bottom: 64%;

        }


        img {
            width: 100%;
            object-fit: contain;
            height: 100%;
        }

        &__side {
            max-width: 100%;
            width: 528px;
            height: 100%;
            border-radius: 30px;
            -webkit-transition: all .8s ease;
            transition: all .8s ease;
            position: absolute;
            top: 0;
            left: calc(50% - (528px /2));
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border-radius: 3px;
            overflow: hidden;
            -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
            box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
            // animation: 5s ease 0s infinite beatChild;

            @keyframes beatChild {

                0%,
                50%,
                100% {
                    box-shadow: 1px 10px 20px rgba(0, 0, 0, 0.4);
                }

                30%,
                80% {
                    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
                }
            }

            @media (max-width: 758px) {
                left: 0;
                width: 100%;
            }

            &--front {
                background: url("../../assets/images/jpg/for_card.jpg") no-repeat center;
                background-size: cover;
                height: 100%;
                // filter: sepia(0.4);
                border-radius: 10px;
                // background-image: linear-gradient(to right bottom, var(--secondary-color), #a7a7a7);
                text-align: center;
                font-family: var(--font-s-r);
                letter-spacing: 0.2rem;
                padding: 10px;

                @media (max-width: 758px) {
                    font-size: 0.875em;
                    letter-spacing: 0;
                }



                .rotate {
                    width: 100px;
                    height: auto;
                    filter: brightness(4) drop-shadow(2px 4px 6px black);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &--back {
                border-radius: 10px;
                -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);

                button {
                    position: absolute;
                    top: 10%;
                    right: 14%;
                }
            }
        }


        &:hover .card__side--front {
            -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
        }

        &:hover .card__side--back {
            -webkit-transform: rotateX(0);
            transform: rotateX(0);
        }
    }

    h3 {
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

}