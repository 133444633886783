#menuFood {
    text-align: center;

    .menu-food {
        h2 {
            font-family: var(--font-p-b);
            font-size: 1.25rem;

            &:first-of-type {
                margin-bottom: 10px;
                text-transform: inherit;
            }

            &:not(:first-of-type) {
                margin: 30px 0 10px;
            }
        }

        p {
            margin-bottom: 10px;
            font-family: var(--font-s-r-i);
            font-size: 1.125rem;
            letter-spacing: 0.06rem;
            line-height: 1.5rem;
        }
    }
}