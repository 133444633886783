#yourWishes {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 40px);

    .card-parent {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 80px;
        row-gap: 60px;
        justify-content: center;
        max-width: 90vw;

        .card-child {
            position: relative;

            .card {

                width: auto;
                height: 260px;
                padding: 10px 10px 20px;
                background-color: #fff;
                position: relative;
                opacity: 1;
                border-radius: 10px;
                font-family: var(--font-d);
                text-align: center;
                background-repeat: no-repeat;
                background-size: cover;

                .description {
                    border-radius: 6px;
                    padding: 10px;
                    // backdrop-filter: blur(8px);
                    // background-color: hsla(0, 0%, 100%, .7);

                    h3 {
                        text-align: center;
                        font-size: 1.425rem;
                        margin-bottom: 10px;
                        letter-spacing: 0.1rem;
                        white-space: nowrap;
                    }

                    span {
                        display: block;

                        &.date {
                            font-size: 0.875rem;
                            letter-spacing: 0.1rem;
                        }


                    }

                    .note {
                        margin-top: 40px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        max-height: 110px;
                        max-width: 200px;
                        letter-spacing: 0.1rem;

                        span {

                            line-height: 1.3rem;
                            display: block;
                        }
                    }
                }

                &.kinti-dario {
                    .description {
                        background-image: url(../../assets/images/jpg/propose/fot_4.jpg);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: relative;
                        z-index: 1;

                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            top: 0;
                            left: 0;
                            height: 100%;
                            z-index: -1;
                            background-color: rgba(255, 255, 255, 0.8);
                            transition: all 0.3s;
                        }
                    }
                    &:hover {
                        .description::after{
                            background-color: rgba(255, 255, 255, 0);

                        }
                    }
                }
            }

            &:nth-child(odd) {
                .card {
                    transform: rotate(15deg);
                }

                &::after {
                    transform: rotate(10deg);
                }
            }

            &:nth-child(even) {
                .card {
                    transform: rotate(-15deg);
                }

                &::after {
                    transform: rotate(-10deg);
                }
            }

            &::after {
                content: '';
                border-radius: 6px;
                position: absolute;
                width: 90%;
                height: 98%;
                background-color: rgba(0, 0, 0, 0.1);
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
                top: 6%;
                z-index: -1;
                right: 0%;
            }
        }
    }

    .alert-box {
        position: fixed;
        z-index: 1;
        transition: all 0.3s;

        .for-upload {
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            border-radius: 100% 100% 100% 100%;
            display: flex;
            justify-content: center;
            background-color: #ffd397;
            border: none;
            height: 100px;
            z-index: -1;
            font-family: var(--font-d);
            font-size: 1rem;
            box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.10);

            span {
                display: block;
                border-radius: 100%;
                border: 2px solid var(--primary-color);
                width: 30px;
                height: 30px;
                transform: translateY(6px);
                background-color: #ffd397;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0;
                transition: all 0.3s;
            }

            // &::after {
            //     content: '';
            //     position: absolute;
            //     left: 50%;
            //     top: -12px;
            //     width: 114px;
            //     height: 149px;
            //     transform: translateX(-50%);
            //     background-color: #ffd397;
            //     border-radius: 100% 100% 0 0;
            //     z-index: -2;
            // }

            &:hover {
                span {
                    background-color: var(--primary-color);
                    color: var(--secondary-color);
                }
            }
        }

        .alert {
            width: 100%;
            z-index: 1;
            text-align: center;
            margin: 0;

            @media (max-width: 768px) {
                padding-inline: 70px;
            }
        }
    }
}