.form,
.other-form {
    position: relative;
    max-width: 100%;

    .image-box {
        max-width: 250px;
        position: absolute;
        opacity: 0.4;

        @media (max-width: 1280px) {
            opacity: 0.2;
        }

        @media (max-width: 768px) {
            display: none;
        }

        img {
            border-radius: 6px;
            padding: 10px 10px 30px;
            max-width: 100%;
            background-color: #fff;
            z-index: 1;
            filter: saturate(0.5) contrast(2) brightness(1) sepia(0.5);
        }

        &::after {
            content: '';
            border-radius: 6px;
            position: absolute;
            width: 80%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);

            top: 4%;
            z-index: -1;
        }

        &.right {
            top: 0;
            right: 50px;
            transform: rotate(15deg);

            &::after {
                right: 0%;
                transform: rotate(10deg);
            }
        }

        &.left {
            bottom: -10%;
            left: 50px;
            transform: rotate(-15deg);

            &::after {
                left: 0%;
                transform: rotate(-10deg);
            }
        }
    }

    fieldset:not(.for-alert) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        max-width: 60vw;
        margin: 20px auto;
        gap: 20px;
        position: relative;

        @media (max-width: 768px) {
            max-width: 100%;

        }

        .form-group {

            label:not(.no-label),
            .label:not(.no-label) {
                // text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
                font-family: var(--font-s-sb);
                text-shadow: 0 0 1px rgba(255, 255, 255, 1);

                filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));

                @media (max-width: 768px) {
                    font-size: 1rem;

                }

                &.required {
                    &::after {
                        content: '*';
                    }
                }
            }
        }

    }

    .action-box {
        position: sticky;
        bottom: 0;
        margin: 0 auto;
        text-align: center;
        padding: 10px 0;

        button {
            font-size: 1rem;
        }
    }
}

.family-form {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    >div {
        flex: 1;

        @media (max-width: 768px) {
            flex: 2;
        }

        &.age-box {
            flex: unset;
            width: calc(10% - 10px);

            @media (max-width: 768px) {
                width: calc(20% - 10px);
            }
        }

        &.action-box {
            width: calc(80% - 10px);
            display: flex;
            justify-content: flex-end;

            @media (max-width: 768px) {
                flex: 1;
                width: auto;
            }

            &.for-add {
                justify-content: flex-start;
            }
        }

        &.action-box {
            margin-top: auto;

            button {
                height: 30px;
                width: 30px;
                border-radius: 100%;
                position: relative;
                border: 2px solid var(--primary-color);
                font-size: 0;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    background-color: var(--primary-color);
                }

                &::before {
                    height: 10px;
                    width: 2px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 30px;

                }

                &::after {
                    height: 2px;
                    width: 10px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 30px;

                }

                &.remove {
                    &::before {
                        content: none;
                    }
                }

                &:hover {
                    background-color: var(--primary-color);

                    &::before,
                    &::after {
                        background-color: var(--secondary-color);
                    }
                }
            }
        }

        &.for-children {
            min-width: 100%;
            border-bottom: 1px solid var(--primary-color);
            padding-bottom: 20px;
        }
    }
}

.form-group {
    margin-bottom: 30px;
    flex: 0 0 auto;
    width: calc(50% - 10px);
    margin-bottom: auto;
    margin-top: 20px;

    &.email-box {
        width: calc(80% - 10px);

        @media (max-width: 768px) {
            width: 100%;

        }
    }

    &.age-box {
        width: calc(20% - 10px);

        @media (max-width: 768px) {
            width: 100%;

        }
    }

    @media (max-width: 768px) {
        width: 100%;

    }
}

input:not([type=radio]) {
    height: 30px;
    border: none;
    border-bottom: 1px solid var(--primary-color);
    border-radius: 0px;
    // background-color: var(--secondary-color);
    width: 100%;
    background-color: transparent;
    padding-left: 10px;
    color: var(--primary-color);
    font-size: 1rem;
    color: var(--primary-color);
    filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
}



textarea {
    border: none;
    border-bottom: 1px solid var(--primary-color);
    padding: 10px;
    border-radius: 0;
    width: 100%;
    // background-color: var(--secondary-color);
    font-size: 1rem;
    color: var(--primary-color);
    filter: drop-shadow(0px 0px 1px rgba(255, 255, 255, 1));
    background-color: transparent;
}

.mb-10 {
    margin-bottom: 10px;
}

.other-form {
    .form-group {
        width: 100%;

        &.arriving-box {
            width: calc(50% - 10px);

            @media (max-width: 768px) {
                width: 100%;

            }

            input {
                width: auto;
            }
        }

        &.leaving-box {
            width: calc(50% - 10px);

            @media (max-width: 768px) {
                width: 100%;

            }

            input {
                width: auto;
            }
        }
    }
}

fieldset:not(.for-alert) {
    width: 100%;
    padding-bottom: 40px;
    position: relative;

    // &::after {
    //     content: '';
    //     background: linear-gradient(270deg, rgba(105, 68, 78, 0) 10%, #6a424d 100%);
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     height: 1px;
    //     width: 100%;
    // }

    &.legend {
        justify-content: flex-start;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }

    legend {
        background-color: var(--primary-color);
        border-radius: 100px;
        padding: 4px 14px;
        color: var(--secondary-color);
        margin: 0px 0 10px;
        display: block;
        position: relative;

        &::after {
            content: '';
            background: linear-gradient(270deg, rgba(105, 68, 78, 0) 10%, #6a424d 100%);
            position: absolute;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            height: 1px;
            width: 100%;

            @media (max-width: 768px) {
                content: none;
            }
        }

    }

    &:last-child {
        &::after {
            content: none;
        }
    }
}

.for-alert {
    margin: 0 auto;
    max-width: 60vw;
    position: sticky;
    bottom: 0;
    z-index: 1;
}