main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 70%;
    margin: 0 auto;
    z-index: 1;
    margin-bottom: 40px;
    position: relative;
    letter-spacing: 8px;
    line-height: 1.5rem;
    font-family: var(--font-d);

    @media (max-width: 1200px) {
        letter-spacing: 4px;
        max-width: 90%;
    }

    @media (max-width: 768px) and (orientation: landscape) {
        max-width: 90%;
    }

    .main-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: -30px;
        margin-bottom: 160px;

        .image-box {
            background-color: var(--secondary-color);
            margin-top: -50px;
            border-radius: 100%;

            img {
                border-radius: 100%;
            }
        }

        @media (max-width: 768px) {
            margin-top: 0px;
            margin-bottom: 100px;

            .image-box {
                margin-top: -30px;
                max-width: 20%;
                border-radius: 100px;
            }
        }

        .avatar {
            width: 200px;
            height: 200px;
            border-radius: 100%;
            //border: 1px solid var(--primary-color);
            position: relative;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            background-color: var(--secondary-color);

            @media (max-width: 768px) {
                width: 100px;
                height: 100px;
                margin-top: -20px;
            }

            &::after {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                padding-top: 20px;
                font-size: 2rem;
                letter-spacing: 8px;

                @media (max-width: 768px) {
                    letter-spacing: 4px;
                    font-size: 1.2rem;
                }
            }

            &:first-child {
                background-image: url("../../assets/images/png/kinti.png");

                &::after {
                    content: "Kintija";
                }
            }

            &:last-child {
                background-image: url("../../assets/images/png/dario.png");

                &::after {
                    content: "Dario";
                }
            }
        }
    }

    .main-middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        letter-spacing: 6px;

        p {
            line-height: 2.6rem;

            @media (max-width: 768px) {
                line-height: 1.8rem;
            }
        }

        @media (max-width: 768px) {
            letter-spacing: 4px;
        }

        @media (max-width: 768px) {
            img {
                max-width: 14%;
            }
        }

        .date-box-change {
            display: flex;
            margin: 100px 0;
            line-height: normal;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 30px;

                &:last-child {
                    margin: 0;
                }

                span {
                    font-weight: bold;

                    &.number {
                        font-size: 3rem;
                        color: #eeb74d;

                        @media (max-width: 768px) {
                            font-size: 2.4rem;
                        }
                    }

                    &:not(.number) {
                        font-size: 1.4rem;
                        margin-top: 10px;

                        @media (max-width: 768px) {
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }

        .confirm {
            margin-bottom: 100px;
            font-size: 1rem;
            border-bottom: 1px solid rgba(105, 68, 78, 0.3);
            letter-spacing: 2px;
            padding: 0 30px;

            @media (max-width: 768px) {
                font-size: 0.875rem;
                padding: 0;
            }
        }

        .date-box {
            position: relative;
            display: flex;
            width: 40%;
            justify-content: space-between;
            margin: 50px 0 80px;

            @media (max-width: 768px) {
                width: 60%;
            }

            &::after {
                content: "";
                height: 1px;
                background-color: var(--primary-color);
                position: absolute;
                top: 50%;
                width: 100%;
            }

            .day,
            .month,
            .year {
                background-color: var(--primary-color);
                width: 50px;
                height: 50px;
                font-size: 1.4rem;
                border-radius: 0.25rem;
                position: absolute;
                top: 50%;
                color: var(--secondary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1;

                @media (max-width: 768px) {
                    width: 40px;
                    height: 40px;
                    font-size: 1.2rem;
                }
            }

            .day {
                transform: translateY(calc(-50% + 10px));
            }

            .month {
                left: 50%;
                transform: translate(-50%, calc(-50% - 10px));
            }

            .year {
                transform: translateY(calc(-50% + 14px));
                right: 0;
            }
        }
    }

    img {
        max-width: 100%;
    }

    p {
        font-size: 2rem;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    .main-bottom {
        height: auto;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 5%;

        @media (min-width: 1024px) {
            margin: 0 auto;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            height: auto;
            margin: 0 auto;
        }

        .location-box {
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: center;
            height: 90vh;
            width: 100%;
            border-radius: 10px;
            display: inline-flex;
            flex-direction: column;
            padding: 20px;
            justify-content: space-between;

            @media (max-width: 900px) {
                width: auto;
                padding: 10px;
            }

            @media (max-width: 900px) and (orientation: landscape) {
                height: 100%;
                width: 100%;
            }

            &:first-child {
                background-image: url("../../assets/images/jpg/ceremony.jpg");
                margin-right: 10px;

                @media (max-width: 768px) {
                    margin: 0;
                    margin-bottom: 20px;
                }
            }

            &:last-child {
                background-image: url("../../assets/images/jpg/reception.jpg");
                margin-left: 10px;

                @media (max-width: 768px) {
                    margin: 0;
                }
            }

            >div {
                background-color: rgba(#f6f5fa, 0.4);
                border-radius: 4px;
                display: flex;
                flex-direction: column;
                align-items: center;

                @media (max-width: 768px) {
                    width: auto;
                    max-width: 100%;
                }

                span {
                    text-shadow: -1px -1px 1px var(--secondary-color);
                }

                h2 {
                    font-size: 3em;
                    position: relative;
                    margin: 0;
                    margin-bottom: 40px;
                    line-height: normal;

                    &::after,
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -10px;
                        left: -25px;
                        width: calc(100% + 40px);
                    }

                    &::after {
                        border-bottom: 1px solid rgba(105, 60, 78, 0.3);
                    }

                    &::before {
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    }

                    @media (max-width: 768px) {
                        font-size: 1.6rem;
                    }
                }

                p {
                    font-size: 1.4rem;
                    margin: 0;
                    margin-bottom: 20px;

                    @media (max-width: 768px) {
                        font-size: 1.2rem;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            >div:first-child {
                padding: 40px 0;
                margin-bottom: 100px;

                @media (max-width: 768px) {
                    padding: 20px 0;
                }
            }

            >div:last-child {
                padding: 10px;

                >div {
                    width: 100%;
                }
            }
        }
    }

    .details-box {
        border-bottom: 1px solid rgba(105, 68, 78, 0.3);
        max-width: 100%;
        text-align: center;
        padding: 0 30px;
        font-size: 1rem;
        letter-spacing: 2px;

        @media (max-width: 768px) {
            margin-top: 30px;
            font-size: 0.875rem;
            padding: 0;
        }
    }
}