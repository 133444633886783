.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  z-index: 101;
  text-align: center;
  top: -100%;
  transition: all 0.5s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  flex-direction: column;

  &-header {
   
  }

  button.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 100%;
    height: 40px;
    width: 40px;
  }

  .image-box {
    overflow: hidden;

    img {
      height: 100%;
      margin: 0 auto;
      width: 100%;
      object-fit: contain;
      border-radius: 8px;
      max-height: 100vh;
      max-width: 100%;

      @media (max-width: 768px) {
        width: calc(100% - 30px);
      }
    }
  }

  &.show {
    top: 0;
    visibility: visible;
    transition: all 1s;
  }

  &-body {
    .is-form & {
      height: calc(100vh - 200px);
      overflow-y: auto;
      overflow-x: hidden;
      max-width: 90vw;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    .frame-box {
      iframe {
        width: calc(100vw - 20px);
        height: calc(100vh - 200px);
        border-radius: 8px;
      }
    }
    .details {
      p {
        margin-bottom: 10px;
        line-height: 2rem;
      }
    }
  }
}