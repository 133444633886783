.language-box {
    position: fixed;
    top: 35px;
    right: 30px;
    z-index: 100;
    font-family: var(--font-d);

    .isForm & {
        z-index: 102;
        right: unset;
        left: 30px;

        @media (max-width: 768px) {
            top: 25px;
            left: 10px;
        }
    }

    @media (max-width: 768px) {
        top: 15px;
        right: 10px;
    }

    a {
        margin-left: 10px;
        border: 2px solid #69444E;
        background-color: var(--secondary-color);
        width: 30px;
        height: 30px;
        border-radius: 100%;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        text-decoration: none;
        color: var(--primary-color);
        font-size: 0.725rem;

        &.active,
        &:hover {
            background-color: #69444E;
            color: #FFF;
            border: 2px solid var(--primary-color);

        }

        .isOpenMenu & {
            border: 2px solid var(--secondary-color);
            background-color: var(--primary-color);
            color: var(--secondary-color);

            &:hover {
                background-color: var(--secondary-color);
                color: var(--primary-color);
            }

            &.active {
                background-color: var(--secondary-color);
                color: var(--primary-color);
            }
        }

        &.active {
            pointer-events: none;
        }
    }
}