#riga {
    .box-image {
        position: relative;

        img {
            background-color: var(--secondary-color);
            padding: 4px;
            border-radius: 8px;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            filter: drop-shadow(0 2px 15px rgba(0, 0, 0, 0.2));

            @media (max-width: 768px) { 
                filter: unset;
            }
        }

        span {
            position: absolute;
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            padding: 4px 10px;
            font-family: var(--font-s-b-i);
            letter-spacing: 0.1rem;
            font-size: 0.725rem;
            border-radius: 6px;
            background-color: rgba(246, 245, 250, 0.7);

            @media (max-width: 768px) {
                font-size: 0.625rem;
                padding: 2px 4px;
                width: fit-content;
                text-align: center;
            }
        }

    }

    h3 {
        letter-spacing: 0.3rem;
        font-family: var(--font-s-b);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(8px);
        background-color: rgba(246, 245, 250, 0.7);
        padding: 10px 20px;
        border-radius: 4px;

        @media (max-width: 768px) {
            font-size: 1.125rem;
        }
    }

    p {
        padding: 20px 0;
        letter-spacing: 0.1rem;
        line-height: 1.8rem;

        @media (max-width: 768px) {
            font-size: 0.875rem;
        }
    }

    .section__child {
        border: 2px solid var(--primary-color);
        padding: 20px;

        @media (max-width: 768px) {
            padding: 10px;
            border: 1px solid var(--primary-color);
        }

        
    }
}