#menuRiga {
    margin: 50px 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: center;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        background-color: var(--secondary-color);
        padding: 15px 60px;
        left: 50px;
        justify-content: space-between;
    }

    .button-prev,
    .button-next {
        display: none;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        border: 3px solid var(--primary-color);
        align-items: center;
        justify-content: center;
        position: relative;

        &:disabled {
            opacity: 0.1;
        }
    }


    .button-next {
        border-top: 0;
        border-bottom: 0;
        border-left: 0;

        &::before {
            content: '';
            position: absolute;
            width: 30px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(105, 68, 78, 1) 40%);
            height: 2px;
            right: -1px;
            border-radius: 100px;

        }
    }

    .button-prev {
        border-top: 0;
        border-bottom: 0;
        border-right: 0;

        &::before {
            content: '';
            position: absolute;
            width: 30px;
            background: linear-gradient(90deg, rgba(105, 68, 78, 1) 40%, rgba(255, 255, 255, 1) 100%);
            height: 2px;
            left: -1px;
            border-radius: 100px;

        }
    }

    @media (max-width: 768px) {

        .button-prev,
        .button-next {
            display: flex;
        }
    }

    ul {
        display: inline-flex;
        justify-content: center;
        gap: 20px;
        border-radius: 100px;
        background-color: var(--secondary-color);
        box-shadow: 0px 28px 15px -10px rgba(105, 68, 78, 0.08);
        padding: 10px;
        
        @media (max-width: 768px) {
            padding: 0px;
            box-shadow: none;
        }

        li {
            @media (max-width: 768px) {
                &:not(.active) {
                    display: none;
                }
            }

            button {
                padding: 10px 20px;
                font-size: 1rem;
                font-family: var(--font-s-r);
                transition: all 0.5s;
                position: relative;
                z-index: 1;
                border-radius: 100px;


                @media (max-width: 768px) {
                    pointer-events: none;
                    font-size: 0.875rem;
                    padding: 4px 8px;
                }

                // &.active {
                //     background-color: var(--primary-color);
                //     border-radius: 0;
                //     color: var(--secondary-color);
                //     transition: all 0.3s;
                // }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    height: 100%;
                    top: 0;
                    width: 0px;
                    background-color: var(--primary-color);
                    transition: all 0.5s;
                    z-index: -1;
                    border-radius: 100px;
                }
            }

            &:hover,
            &.active {
                button {
                    color: var(--secondary-color);

                    &::after {
                        width: 100%;
                        height: 100%;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
}