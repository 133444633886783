#OurHistory {
    .date-box-parent {
        height: calc(100vh - 268px);
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        padding: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.16);

        @media (max-width: 1024px) {

            height: 100%;
        }

        .date-box {
            height: 100%;
            background-image: url(../../assets/images/jpg/for_card_old.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-wrap: wrap;
            border-radius: 8px;

            @media (max-width: 1024px) {
                flex-direction: column;
                height: 100%;
                width: 100%;
                flex-wrap: unset;
            }

            &__child {
                width: calc(100% / 5);
                height: 100%;
                position: relative;
                z-index: 1;
                color: var(--secondary-color);
                padding: 20px;
                text-align: center;
                transition: all 2s;
                overflow: hidden;

                @media (max-width: 1024px) {
                    width: 100%;
                    height: 50vh;
                    cursor: pointer;
                }

                @media (max-width: 1024px) and (orientation: landscape) {
                    height: 100vh;
                }


                h3 {
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 1s;
                    font-family: var(--font-d);
                    font-size: 1.7rem;
                    letter-spacing: 0.2rem;
                }


                .details {
                    // opacity: 0;
                    // visibility: hidden;
                    color: var(--primary-color);
                    transform: translateY(-100%);
                    position: relative;
                    height: 100%;
                    width: 100%;
                    // transition: visibility 0.1s 0s, opacity 0.1s 0s, transform 0.1s 0s;
                    transition: transform 1s 0s;

                    @media (max-width: 1024px) {
                        transform: translateX(calc(-100% - 40px));
                    }

                    h4 {
                        margin-bottom: 10px;
                    }

                    button {
                        border-radius: 100px;
                        position: absolute;
                        bottom: calc(20% + 20px);
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 4px 10px;
                    }

                    .description {
                        max-height: calc(80% - 130px);
                        overflow: auto;
                        position: absolute;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    left: 0;
                    height: 100%;
                    top: 0;
                    opacity: 0.9;
                    transition: all 1s;
                    background: rgb(106, 66, 77);
                    background: linear-gradient(90deg, rgba(106, 66, 77, 1) 0%, rgba(153, 112, 123, 1) 100%);
                    z-index: -1;

                    @media (max-width: 1024px) {
                        background: rgb(106, 66, 77);
                        background: linear-gradient(0deg, rgba(106, 66, 77, 1) 0%, rgba(153, 112, 123, 1) 100%);
                    }
                }


                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    height: 100%;
                    top: 0;
                    background-color: var(--secondary-color);
                }

                &:first-child {
                    border-radius: 8px 0 0 8px;

                    @media (max-width: 1024px) {
                        border-radius: 8px 8px 0 0;
                    }
                }

                &:last-child {
                    border-radius: 0 8px 8px 0px;

                    @media (max-width: 1024px) {
                        border-radius: 0px 0px 8px 8px;
                    }
                }

                @media (min-width: 1024px) {
                    &:hover {
                        -webkit-backdrop-filter: blur(10px);
                        backdrop-filter: blur(8px);
                        background-color: rgba(255, 255, 255, 0.7);
                        transition: all 1s;

                        &::before {
                            top: 80%;
                        }

                        h3 {
                            top: 90%;
                        }

                        .details {
                            // opacity: 1;
                            // visibility: visible;
                            // transition: visibility 1s 1s, opacity 1s 1s, transform 1s 0.5s;;
                            transition: transform 1s 0s;
                            transform: translateY(0%);

                            @media (max-width: 1024px) {
                                transform: translateX(0);
                            }

                        }

                    }
                }

                @media (max-width: 1024px) {
                    &.active {
                        -webkit-backdrop-filter: blur(10px);
                        backdrop-filter: blur(8px);
                        background-color: rgba(255, 255, 255, 0.7);
                        transition: all 1s;

                        &::before {
                            top: 80%;
                        }

                        h3 {
                            top: 90%;
                        }

                        .details {
                            // opacity: 1;
                            // visibility: visible;
                            // transition: visibility 1s 1s, opacity 1s 1s, transform 1s 0.5s;;
                            transition: transform 1s 0s;
                            transform: translateY(0%);

                            @media (max-width: 1024px) {
                                transform: translateX(0);
                            }

                        }

                    }
                }

            }
        }

        .photo-box {
            position: absolute;
            left: -100%;
            width: 100%;
            height: 100%;
            top: 0%;
            z-index: 1;
            background: var(--secondary-color);
            border-radius: 8px;
            color: var(--primary-color);
            padding: 10px;
            visibility: hidden;
            transition: all 1s;
            overflow: auto;

            @media (max-width: 1024px) {
                height: calc(100vh - 248px);
            }

            @media (max-width: 1024px) and (orientation: landscape) {
                height: calc(100vh - 128px);
            }


            button.close-button {
                position: sticky;
                left: 100%;
                top: 20px;
                transform: translateY(-50%);
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);

                @media (max-width: 1024px) {
                    position: sticky;
                    position: -webkit-sticky;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &.show {
                left: 0;
                visibility: visible;
            }

            &.exit {
                left: 100%;
            }

            &__child {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                height: 100%;

                @media (min-width: 1024px) {
                    margin-top: -38px;
                }

                .video-box {
                    width: calc(100% / 2 - 8.4px);
                    height: calc(100vh / 3 - 8.4px);

                    video {
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                    }
                }

                img {
                    width: calc(100% / 6 - 8.4px);
                    height: calc(100vh / 3 - 8.4px);
                    object-fit: cover;
                    border-radius: 4px;


                    @media (max-width: 1024px) {
                        &:first-child {
                            margin-top: -40px;
                        }

                        height: auto;
                        width: 100%;
                        max-height: calc(100vh - 268px);
                    }

                    @media (max-width: 1024px) and (orientation: landscape) {
                        max-height: calc(100vh - 148px);
                    }
                }
            }
        }
    }
}