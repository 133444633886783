.countdown {
  display: flex;
  margin-bottom: 100px;
  font-size: 1rem;
  color: var(--secondary-color);
  width: auto;
  justify-content: center;

  @media (max-width: 900px) {
    font-size: 0.725rem;
  }

  >div {
    display: flex;
    margin: 0 20px;
    position: relative;

    @media (max-width: 900px) {
      flex-direction: column;
      margin: 0;
    }

    &:not(:last-child)::before {
      content: "";
      background-color: rgba(105, 68, 78, 0.3);
      position: absolute;
      width: 50%;
      height: 1px;
      left: calc(100% - 50px);
      top: 50%;

      @media (max-width: 900px) {
        content: none;
        margin: 0;
      }
    }

    >div {
      background: var(--primary-color);
      box-shadow: 0 10px 10px rgba(105, 68, 78, 0.5);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 40px;
      margin-right: 30px;
      position: relative;
      width: 130px;
      height: 130px;
      gap: 4px;

      @media (max-width: 900px) {
        margin: 0;
      }

      span {
        letter-spacing: 2px;
      }

      &:not(:last-child)::before {
        content: "";
        background-color: rgba(105, 68, 78, 0.3);
        position: absolute;
        width: 100%;
        height: 1px;
        left: calc(100% - 50px);
        top: 50%;

        @media (max-width: 900px) {
          content: none;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    &:nth-child(1) {
      div:nth-child(1) {
        border-radius: 4px 100% 4px 100%;
        align-items: flex-start;

        @media (max-width: 900px) {
          flex-direction: column-reverse;
          align-items: flex-end;
        }

        span {
          align-self: flex-end;

          @media (max-width: 900px) {
            align-self: flex-start;
            margin-bottom: 8px;
          }
        }
      }

      div:nth-child(2) {
        border-radius: 100% 4px 100% 4px;
        align-items: flex-end;

        span {
          align-self: flex-start;

          .lv_lv & {
            align-self: flex-end;
          }

          @media (max-width: 900px) {
            align-self: flex-start;
          }
        }

        @media (max-width: 900px) {
          margin: 0;
        }
      }
    }

    &:nth-child(2) {
      div:nth-child(1) {
        border-radius: 4px 100% 4px 100%;
        align-items: flex-start;

        @media (max-width: 900px) {
          flex-direction: column-reverse;
          align-items: flex-start;
          border-radius: 100% 4px 100% 4px;
        }

        span {
          align-self: flex-start;

          @media (max-width: 900px) {
            align-self: flex-end;
            margin-bottom: 8px;
          }
        }

        &:not(:last-child)::before {
          @media (max-width: 900px) {
            content: none;
          }
        }
      }

      div:nth-child(2) {
        border-radius: 100% 4px 100% 4px;
        align-items: flex-end;

        @media (max-width: 900px) {
          align-items: flex-start;
          border-radius: 4px 100% 4px 100%;
        }

        span {
          align-self: flex-end;

          @media (max-width: 900px) {
            align-self: flex-start;
          }
        }
      }
    }
  }
}