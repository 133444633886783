#weddingDay {
    overflow: hidden;

    .tab-menu {
        margin: 0 0 40px;
        text-align: center;
        position: relative;

        &::before {
            content: '';
            width: 0%;
            height: 1px;
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(105, 68, 78, 1) 40%, rgba(255, 255, 255, 1) 100%);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            transition: all 2s;
        }

        &::after {
            content: '';
            width: 0%;
            height: 1px;
            background: rgb(105, 68, 78);
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(105, 68, 78, 1) 40%);
            position: absolute;
            right: 50%;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            transition: all 2s;
        }

        &.first {


            &::after {
                width: 50%;
            }
        }

        &.second {

            &::before {
                width: 50%;
            }
        }

        button {
            background-color: var(--secondary-color);
            font-size: 0.875rem;
            border-radius: 4px;
            margin: 0;
            padding: 10px 20px;
            letter-spacing: 0.2rem;
            position: relative;
            transition: all 1s, border 0.3s;
            font-family: var(--font-d);

            @media (max-width: 768px) {
                padding: 6px 8px;
            }

            &:not(.active):hover {
                transition: all 0.3s;
                box-shadow: 0px 2px 6px rgba(105, 68, 78, 0.4);
            }

            &[data-tab=first] {
                border-left: 2px solid var(--primary-color);
                border-top: 2px solid var(--primary-color);
                border-bottom: 2px solid var(--primary-color);
                border-radius: 50px 0 0 50px;
            }

            &[data-tab=second] {
                border-right: 2px solid var(--primary-color);
                border-top: 2px solid var(--primary-color);
                border-bottom: 2px solid var(--primary-color);
                border-radius: 0 50px 50px 0;
            }

            &.active {
                // background-color: var(--primary-color);

                color: var(--secondary-color);
                background-color: var(--primary-color);
                pointer-events: none;

                &[data-tab=first] {
                    // box-shadow: 10px 6px 10px rgba(105, 68, 78, 0.16);
                }

                &[data-tab=second] {
                    // border-right: 2px solid #ae9ca3;

                    // box-shadow: -10px 6px 10px rgba(105, 68, 78, 0.16);

                }

                // &[data-tab=first]::before {
                //     content: '';
                //     background-color: var(--secondary-color);
                //     left: -11px;
                //     top: 0;
                //     width: 10px;
                //     height: 100%;
                //     position: absolute;
                //     z-index: 1;
                // }

                // &[data-tab=second]::after {
                //     content: '';
                //     background-color: var(--secondary-color);
                //     right: -11px;
                //     top: 0;
                //     width: 10px;
                //     height: 100%;
                //     position: absolute;
                //     z-index: 1;
                // }
            }
        }
    }

    .destination-box {
        margin-top: 20px;
        padding: 20px;

        button {
            border-radius: 100px;
            font-size: 1rem;
            padding: 4px 12px;
            transition: all 0.3s;

            @media (max-width: 768px) {
                font-size: 0.875rem;
            }

            &:hover {
                transition: all 0.3s;
                box-shadow: 0px 2px 10px rgba(105, 68, 78, 0.4);
            }
        }
    }
}

.tab-box-parent {
    display: flex;
    // background-image: url(../../assets/images/jpg/riga-2.jpg);
    // background-size: cover;
    // background-position: center;

    .tab-box {
        position: relative;
        display: flex;
        min-width: calc(100% - 20px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: rgba(105, 68, 78, 0.4);
        min-height: 100%;
        padding: 20px;
        transition: all 2s ease-in-out;

        @media (max-width: 768px) {
            flex-direction: column;
            min-width: calc(100% - 20px);

            &:last-child {
                flex-direction: column-reverse;
            }
        }

        &.first {
            background-image: url(../../assets/images/jpg/riga.jpg);
            transform: translateX(-100%);
            border-radius: 8px 0px 0px 8px;
            box-shadow: -8px 2px 10px rgba(105, 68, 78, 0.16);
            margin-left: 20px;

            .tab-box-parent-second & {}

            &.show {
                transform: translateX(0%);
            }

            &::after {
                content: '';
                background: linear-gradient(90deg, rgba(105, 68, 78, 0) 60%, rgba(0, 0, 0, 1) 100%);
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 50%;
                z-index: -1;

                @media (max-width: 768px) {
                    content: none;
                }
            }

        }

        &.second {
            border-radius: 0px 8px 8px 0px;
            background-image: url(../../assets/images/jpg/reception-3.jpg);
            transform: translateX(0%);
            box-shadow: 8px 2px 10px rgba(105, 68, 78, 0.16);

            .tab-box-parent-first & {}

            &.show {
                transform: translateX(calc(-100% - 20px));

                @media (max-width: 768px) {
                    transform: translateX(calc(-100% - 20px));
                }
            }

            .row-image .col-image:first-child {
                align-items: flex-start;
            }

            &::after {
                content: '';
                background: linear-gradient(270deg, rgba(105, 68, 78, 0) 60%, rgba(0, 0, 0, 1) 100%);
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 50%;
                z-index: -1;

                @media (max-width: 768px) {
                    content: none;
                }
            }
        }


        .row-image {
            gap: 10px;
            display: flex;
            flex: 1 1 60%;
            max-height: calc(100vh - 200px);



            .col-image {
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 20px 0;

                &:first-child {
                    align-items: end;
                    flex: 80%;
                    justify-content: center;

                    img:first-child {
                        max-width: 70%;
                        max-height: 60%;
                    }

                    img:last-child {
                        max-width: 60%;
                    }
                }

                &:last-child {
                    justify-content: center;
                    flex: 20%;

                    img {
                        object-position: left;
                    }
                }


            }

            img {
                display: none;
                object-fit: contain;
                max-width: 100%;
                border-radius: 4px;
                // box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
                border: 6px solid rgba(#F6F5FA, 0.7);
            }

        }

        .desciption-box {
            flex: 1 1 40%;
            position: relative;
            padding: 20px;

            @media (max-width: 768px) {
                font-size: 0.875rem;
            }

            &::after {
                content: '';
                position: absolute;
                height: 100%;
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(8px);
                background-color: rgba(255, 255, 255, 0.7);
                width: 100%;
                top: 0%;
                left: 0;
                z-index: -1;
                border-radius: 8px;
            }

            h3 {
                position: relative;
                margin-bottom: 10px;
                margin-left: 20px;

                &::before {
                    content: '';
                    background: url(../../assets/images/png/logo.png) no-repeat center;
                    background-size: contain;
                    position: absolute;
                    left: -30px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 20px;
                    width: 20px;
                    z-index: 0;
                    border-radius: 100%;
                    padding: 2px;
                    background-color: var(--secondary-color);
                    box-shadow: 2px 2px 10px rgba(105, 68, 78, 0.4);
                }
                
                &::after {
                    content: '';
                    position: absolute;
                    left: -40px;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 2px;
                    width: 12px;
                    background-color: var(--secondary-color);
                    box-shadow: 2px 2px 10px rgba(105, 68, 78, 0.4);
                    border-radius: 1px;
                }
            }

            b {
                margin: 0 0 8px 20px;
                display: block;
                font-size: 1rem;
                font-family: var(--font-s-b-i);
            }

            p {
                margin: 0 0 20px 20px;
                line-height: 1.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}