#heroImage {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: 100vh;

  @media (max-width: 1080px) {
    padding: 15px;
  }

  .hero-image-box {
    border-radius: 8px;
    background: url("../../assets/images/jpg/banner_home.jpg") no-repeat center;
    background-attachment: fixed;
    background-size: cover;
    background-position: center 0;
    height: 100%;
    width: 100%;
    position: relative;
    filter: saturate(0.7) contrast(1.5) brightness(1.05);

    @media (max-width: 1080px) {
      background-attachment: unset;
      // height: calc(100vh - 8px);
    }

    // &::before {
    //   content: '';
    //   position: absolute;
    //   background-color: rgba(255,255,255,0.5);
    //   left: 0;
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 0;
    // }
  }

  .name-box {
    letter-spacing: 1rem;
    font-size: 4rem;
    z-index: 1;
    text-align: center;
    position: absolute;
    padding: 40px;
    color: var(--secondary-color);
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
    font-family: var(--font-p-bl-i);
    border-radius: 8px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    // -webkit-backdrop-filter: blur(10px);
    // backdrop-filter: blur(8px);
    // background-color: rgba(255, 255, 255, 0.7);
    right: 10%;

    span {
      display: inline;
      position: relative;
      opacity: 0;
      visibility: hidden;
      transition: all 1s;

    }

    &.end {

      span {
        transition: all 1s;
        opacity: 1 !important;
        visibility: visible;

        &.left {
          left: 0 !important;

        }

        &.right {
          right: 0 !important;
        }
      }

      &.active {
        span.left {
          animation: fromTop 1s forwards;

          @keyframes fromTop {
            0% {
              top: 0px;
            }

            50% {
              top: 50px;
            }

            100% {
              top: 0;
            }
          }
        }

        span.right {
          animation: fromBottom 1s forwards;

          @keyframes fromBottom {
            0% {
              bottom: 0px;
            }

            50% {
              bottom: 50px;
            }

            100% {
              bottom: 0;
            }
          }
        }
      }
    }



    .image-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px 0;

      img {
        border-radius: 100%;
        display: block;
        text-align: center;
        padding: 10px;
        background-color: var(--secondary-color);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
      }

      &::before {
        content: '';
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 50%;
        background-color: var(--secondary-color);
        background: linear-gradient(90deg, rgba(105, 68, 78, 0) 10%, rgba(246, 245, 250, 1) 100%);
        border-radius: 100px;
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        background: linear-gradient(270deg, rgba(105, 68, 78, 0) 10%, rgba(246, 245, 250, 1) 100%);
        transform: translateY(-50%);
        height: 2px;
        width: 50%;
        border-radius: 100px;
        z-index: -1;
      }
    }

    @media (max-width: 768px) {
      font-size: 2rem;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}