.title-seciont-box {
    padding: 80px 15px 100px;
    
    @media (max-width: 768px) and (orientation: landscape) {
        padding: 20px 15px 40px;
    }

    h2 {
        text-align: center;
        position: relative;
        display: inline-block;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
        line-height: 3rem;

        @media (max-width: 768px) {
            #saveToDate & {
                width: auto;
            }

            width: 100%;
        }

        span {
            letter-spacing: 0.5rem;

            @media (max-width: 1080px) {
                letter-spacing: 0.3rem;
            }

            @media (max-width: 768px) {
                max-width: 50%;
            }
        }


        #heroImage & {
            margin-bottom: 0px;

            &::before,
            &::after {
                content: none;
            }
        }

        &::before,
        &::after {
            content: url(../../assets/images/png/image.png);
            position: absolute;
            z-index: 0;
            opacity: 0.3;
            top: 50%;
            transform: translateY(-50%);

            @media (max-width: 768px) {
                opacity: 0.2;
            }

            #saveToDate & {
                content: none;
            }
        }

        &::before {
            border-radius: 100% 0 100% 0;
            // border-top: 2px solid #eeb74d;
            left: -80px;


            @media (max-width: 768px) {
                left: 0;
            }
        }

        &::after {
            border-radius: 100% 0 100% 0;
            // border-bottom: 2px solid #eeb74d;
            right: -80px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1) translateY(-50%);

            @media (max-width: 768px) {
                right: 0;
            }
        }
    }
}

section:not(#heroImage):not(#weddingDay):not(#tips) {
    min-height: calc(100vh - 40px);
    padding: 20px;
}

