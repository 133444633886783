.spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--secondary-color);
    background: rgb(246, 245, 250);
    background: radial-gradient(circle, rgba(246, 245, 250, 1) 0%, rgba(246, 245, 250, 0.5998774509803921) 100%);
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: center;

    .letter {
        position: absolute;
        background-color: var(--secondary-color);
        width: 45px;
        height: 45px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        font-family: var(--font-d);

        span {
            position: absolute;

            &.__k {
                left: 6px;
                top: 6px;
            }

            &.__d {
                right: 6px;
                bottom: 6px;
            }
        }
    }

    img {
        max-width: 100%;
        animation: keyRotate 2s infinite;
    }

    @keyframes keyRotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}