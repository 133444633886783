#riga .section__child.moscovita {
    position: relative;
    border-bottom: none;
    border-right: none;
    border-top: none;
    padding: 0 0px 0 20px;

    @media (max-width: 768px) {
        padding: 0 0px 0 10px;
    }


    .image-2 {
        float: right;
        margin-left: 20px;
        margin-bottom: 20px;
        height: 300px;
        width: 40vw;
        margin-top: -20px;

        @media (max-width: 768px) {
            height: 300px;
            width: 100%;
            margin-top: -70px;
        }

        img {
            height: 300px;
            width: 40vw;
            object-position: top;
            filter: drop-shadow(0 2px 15px rgba(0, 0, 0, 0.2));

            @media (max-width: 768px) {
                height: 300px;
                width: 100vw;
                filter: unset;
            }
        }

        span {
            right: 10px;
            bottom: 10px;

            @media (max-width: 768px) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .image-3 {
        float: left;
        margin-right: 20px;
        height: 400px;
        width: 40vw;
        z-index: 1;

        @media (max-width: 768px) {
            width: 100%;
            height: 200%;
        }

        img {
            height: 400px;
            width: 40vw;
            object-position: top;
            filter: drop-shadow(0 2px 15px rgba(0, 0, 0, 0.2));

            @media (max-width: 768px) {
                width: 100%;
                height: 200px;
                filter: unset;
            }
        }

        span {
            left: 10px;
            bottom: 10px;

            @media (max-width: 768px) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }



    h3 {
        position: relative;
        z-index: 1;
        display: inline-block;
    }
}