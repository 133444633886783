#saveToDate {
    .save-date-box-parent {
        margin: 80px auto 20px;
        // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
        max-width: 60vw;
        // padding: 30px;
        border-radius: 6px;

        @media (max-width: 1280px) {
            max-width: 80vw;
        }

        @media (max-width: 768px) {
            max-width: 100%;
        }

        .title-seciont-box {
            padding: 20px 0 50px;
            position: relative;

            &::before {
                content: '';
                width: 50%;
                height: 1px;
                background: rgb(255, 255, 255);
                background: linear-gradient(90deg, rgba(105, 68, 78, 1) 40%, rgba(255, 255, 255, 1) 100%);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1;
                transition: all 2s;
            }

            &::after {
                content: '';
                width: 50%;
                height: 1px;
                background: #69444e;
                background: linear-gradient(90deg, white 0%, #69444e 40%);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1;
                transition: all 2s;
            }

            h2 {
                position: relative;
                padding: 0 20px;
                background: var(--secondary-color);

            }
        }

        .save-date-box {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            @media (max-width: 768px) {
                max-width: 100%;
                gap: 10px;
                flex-direction: column;
            }

            .save-date-box--child {
                min-width: calc((100% / 3) - 13.399999px);
                height: 60vh;
                position: relative;
                border-radius: 4px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;


                @media (max-width: 768px) {
                    max-width: 40vw;
                    min-width: 40vw;
                    height: calc(100vh / 3 - 10px);
                    background-size: cover;
                    margin: 0 auto;
                }

                @media (max-width: 768px) and (orientation: landscape) {
                    height: calc(100vh - 10px);
                }

                span {
                    position: absolute;
                    bottom: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translateX(-50%);
                    font-size: 8rem;
                    font-family: var(--font-p-bl-i);
                    color: var(--secondary-color);
                    line-height: 5.6rem;
                    text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                    transition: all 1s;

                    @media (max-width: 768px) {
                        left: unset;
                        right: 60px;
                        bottom: 50%;
                        transform: translateY(-50%);
                        font-size: 6rem;
                        line-height: 0;
                        text-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
                    }
                }


                &:nth-child(1) {
                    background-image: url(../../assets/images/jpg/saveToDate/2.jpg);
                }

                &:nth-child(2) {
                    background-image: url(../../assets/images/jpg/saveToDate/1.jpg);
                }

                &:nth-child(3) {
                    background-image: url(../../assets/images/jpg/saveToDate/3.jpg);
                }
            }
        }
    }

    p {
        text-align: center;
        margin: 40px 0 10px;
        font-family: var(--font-d);
        font-size: 1.4rem;
        letter-spacing: 1.4rem;

        @media (max-width: 768px) {
            font-size: 1.4rem;
            letter-spacing: 0.3rem;
            margin: 30px 0 10px;
        }
    }

    &.active {
        .save-date-box-parent .save-date-box .save-date-box--child {
            span {
                opacity: 1;
                bottom: 0%;

                @media (max-width: 768px) {
                    left: unset;
                    right: -60px;
                    bottom: 50%;
                    transform: translateY(-50%);
                    font-size: 6rem;
                    line-height: 0;
                    text-shadow: 2px 0px 10px rgba(0, 0, 0, 0.4);
                }
            }
        }
    }
}