button.btn.for-form {
    position: fixed;
    bottom: 30px;
    left: 30px;
    height: 40px;
    width: 40px;
    border-radius: 100% 100% 100% 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1;

    @media (max-width: 768px) {
        left: 10px;
        bottom: 10px;
    }

    span {
        display: block;
        background-color: var(--primary-color);
        border-radius: 100px;
        height: 2px;
        margin-bottom: 4px;
        position: relative;

        &:first-child {
            margin-top: 4px;
        }

        &:last-child {
            width: 75%;
        }

        &::before {
            content: '';
            display: block;
            width: 4px;
            height: 2px;
            background-color: var(--secondary-color);
        }

        &::after {
            content: '';
            display: block;
            width: 2px;
            position: absolute;
            left: 0;
            top: 0;
            height: 2px;
            background-color: var(--primary-color);
        }
    }

    &::after {
        content: 'Uzraksti novēlējumu!';
        background-color: var(--primary-color);
        position: absolute;
        left: calc(100% + 2px);
        width: 0;
        opacity: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s;
        border-radius: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 2px solid var(--secondary-color);
        visibility: hidden;
    }

    .it_it & {
        &::after {
            content: 'Lascia un augurio';
        }
    }

    &:hover {
        background-color: var(--primary-color);

        span {
            background-color: var(--secondary-color);

            &::before {
                background-color: var(--primary-color);
            }

            &::after {
                background-color: var(--secondary-color);
            }
        }

        &::after {
            width: 200px;
            transition: all 0.3s;
            opacity: 1;
            visibility: visible;
        }
    }
}